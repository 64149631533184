@import '~antd/dist/antd.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/* .ant-layout-content{
  background: white;
} */
Table {
  background-color: #EEF5CE;
  color: black;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
  background: #e9f5e7;
  color: black;
}


.App-link {
  color: #61dafb;
}


/* custom css */
.rightPannel {
  background: #ebebeb !important;
  margin-top: 113px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rightPannel {
  margin-left: 292px !important;
}

@media screen and (max-width: 767px) {
  .rightPannel {
    margin-left: 75px !important;
  }
}

.ant-layout {
  background-color: #ebebeb;
}

/* Ant table  */
.ant-table-thead>tr>th {
  letter-spacing: 0px;
  color: #434343;
  font-family: "Poppins";
  font-size: 17px;
  background: white;
}

.ant-layout-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}

.cancelled {
  background: red !important;
  color: white !important;
  border-radius: 10px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.pending {
  background: #88841d !important;
  color: white !important;
  border-radius: 10px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.out_for_delivery {
  background: #D6D00A !important;
  color: white !important;
  border-radius: 10px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.delivered {
  background: #10D324 !important;
  color: white !important;
  border-radius: 10px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.ant-table-row {
  background: #E3E3E3;
}

.ant-table-tbody>tr>td {
  border-bottom: 17px solid #EEF5CE !important;
  /* border-bottom:none; */
}

/* modal  */

.ant-modal-content {
  border: 1px solid #707070;
  border-radius: 18px;
}

.ant-modal-body p {
  font-family: "Poppins";
  color: #1E4321 !important;
  font-size: 16px;
}

.ant-modal-close {
  /* display: none */
}

.ant-modal-body {
  justify-content: center;
  align-items: center;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}


.sub_cat_modal .ant-modal-body {
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}



tbody .ant-table-cell {
  font-family: "Poppins";
  font-size: 15px;
  letter-spacing: 0px;
  color: #434343;
}

.table td,
.table th {
  border-top: none !important;
}


.table thead {
  background-color: #EEEEEE !important;
}

.table tbody tr {
  background: #FFFFFF !important;
  border-top: 10px solid #EEE !important;
}

.table tfoot {
  background: #EEEEEE !important;

}

/* user page search  */
.ant-page-header-heading-extra {
  margin-top: 20px !important;
  text-align: center;
}

/* sidbar css */

.ant-layout-sider {
  /* padding-top:20px; */
  border-right: 1px solid #dedede;
}

.ant-menu {
  background: transparent;
  width: 94% !important;
  margin: auto;
}

.ant-menu-item {
  height: 48px !important;
  border-radius: 12px !important;
  /* padding-left: 24px; */
  background: white;
  /* margin-bottom: 18px; */
}

.ant-menu-submenu {
  /* height:50px!important; */
  border-radius: 12px !important;
  background: white;
  /* margin-bottom: 10px; */
}

.ant-menu-sub.ant-menu-inline {
  background-color: #80c36b !important;
  border-radius: 28px !important;
  color: white !important;

}

.ant-menu-item-selected {
  background: #80c36b !important;
  border-right: none !important;
  /* color:white!important; */
}

.ant-menu-submenu-active {
  background: #80c36b !important;

  /* color:rgb(165, 143, 143)!important */
}

.ant-menu-submenu-active .ant-menu-title-content {
  color: white !important;
  /* color:rgb(165, 143, 143)!important */
}

.ant-menu-submenu-active .ant-menu-item-icon {
  filter: brightness(0) invert(1)
}

.ant-menu-item-active {
  background: #80c36b !important;
  color: white !important
}

.ant-menu-title-content {
  color: #5c4d4d !important;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 3 !important;
  padding-left: 5px;
}

.ant-menu-item-active .ant-menu-title-content {
  /* background: red!important; */
  color: white !important
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #80c36b;
  /* width:90%!important; */
  border-radius: 12px !important;
}

.ant-menu-submenu {
  margin-top: 18px;
}

.ant-menu-item {
  margin-top: 18px !important;
}

.ant-menu-submenu .ant-menu-item {
  background-color: #80c36b !important;
  padding-left: 60px !important;
  margin-top: -10px !important;
  /* margin-bottom: -10px!important; */

}

.ant-menu-sub .ant-menu-item .ant-menu-title-content {
  color: #F6FF06 !important;
  font-size: 14px !important;

}

.ant-menu-submenu-open {
  background-color: #80c36b !important;

}

.ant-menu-submenu-open .ant-menu-title-content {
  color: white !important;

}

.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

/* .ant-menu-inline .ant-menu-submenu{
  padding-bottom: 0px!important;
} */
.ant-menu-submenu-arrow {
  display: none !important;
}

.ant-menu-submenu-open .ant-menu-submenu-title {
  color: white !important
}

.ant-menu-submenu {
  padding-top: 1px !important;
  padding-bottom: 1px !important;

}

.ant-menu-item-selected {
  border-right: none !important;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: white !important;
}

.ant-menu-submenu-open .ant-menu-item-icon {

  filter: brightness(0) invert(1);
}

.ant-menu-item-active .ant-menu-item-icon {
  filter: brightness(0) invert(1);
}

.ant-menu-item-selected .ant-menu-item-icon {
  filter: brightness(0) invert(1);
}

/* .ant-menu-item-active {
    color: white!important;
}
.ant-menu-submenu {
    background: white!important;
    color: black!important;
}
.ant-menu-submenu-open .ant-menu-title-content {
    color: white!important;
    border-bottom-left-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}
.ant-menu-submenu-open .ant-menu-item-icon{
  
  filter: brightness(0) invert(1);
}
.ant-menu-sub{
    color: white!important;
    border-bottom-left-radius: 12px!important;
    border-bottom-right-radius: 12px!important;
    margin-top: -10px!important;
    padding-bottom: 10px!important;
} */
/* .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, 
.ant-menu-light .ant-menu-submenu-title:hover{
    color:black;
    background:white
}
.ant-menu-light .ant-menu-item, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu, 
.ant-menu-light .ant-menu-submenu-title{
    
    background:#80c36b;
    color: black!important;
} */
/* .ant-menu-submenu-title{
    background: transparent!important;
}
.ant-menu-submenu-arrow{
    display: none!important;
}
.ant-menu-item::after{
    border-right:none!important;
} */

/* .ant-menu-sub a{
    background-color: #80c36b !important;
    color: #F6FF06!important;
    font-size: 14px!important;
}

.ant-menu-item-selected {
    background-color: #80c36b !important;
    color:white!important;
    border-radius: 0 13rem 13rem 0;
  }
.ant-menu-item-selected .ant-menu-item-icon {
  filter: brightness(0) invert(1);
  } */

/* .ant-menu-item-selected a{
 
    color:white!important;
    border-radius: 0 13rem 13rem 0;
  } */
/* .ant-menu-item-selected .ant-menu-title-content {
   
    color:white!important;

  }
.ant-menu-item-selected svg {
   
    color:white!important;

  }

.ant-menu-submenu-open{
    background-color: #80c36b !important;
  }

.ant-menu-sub {
    background-color: #80c36b !important;
    color: white!important;
  }

  .ant-menu-inline {
    padding-left: 11px;
    padding-right: 10px;
}

.ant-menu-title-content {
    padding-left: 5px;
    line-height: 3!important;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color:#5C4D4D!important;
} */


/* loader */
.overlayy {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(242, 236, 236, 0.577);
  z-index: 99;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 65%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* category */
.btn_add_category {
  background: #2D6F9B;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  color: #FFFFFF;
  font-size: 15px;
  height: 49px !important
}

.btn_add_category:hover {
  color: #FFFFFF;
}

.category_input {
  border-radius: 8px;
  border: 1px solid white;
}

@import url(https://fonts.googleapis.com/css?family=Chivo);
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: white;
  font-family: 'Chivo', arial, tahoma, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.ant-picker-clear {
  display: none !important;
}

.file_upload {
  margin: 0 auto;
  height: calc(1.8em + .75rem + 2px);
  border-radius: 8px;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 3px;
}

.file_upload label {
  margin: 0;
  padding: 5px;
  width: auto;
  max-width: 300px;
  height: auto;
  background-color: #6ABDEE;
  border: none;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
  -webkit-border-radius: 5px;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}



[type=file] {
  display: none;
}


textarea:focus,
.btn:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 transparent;
  /* border:none!important */
}

/* category table */
.category_page .ant-page-header {
  background-color: #d3eeed !important;
}

.category_page .ant-layout-content {
  background-color: transparent !important;
}

.category_page .ant-table-tbody>tr>td {
  border-bottom: 10px solid #D3EEED !important;
}

.category_page .ant-table-row {
  background: #ffffff !important;
}
.category_page .title {
  color: #325924!important;
  font-weight: 600!important;
}
.category_page .category_img {
  height: 80px!important
}
.category_page .action_icons img {
  height: 24px!important
}


/* offer table */
.offer_content{
  min-height: 75vh;
  padding: 10px;

}
.offer_page .ant-page-header {
  background-color: #F0FFCE !important;
}

.offer_page .ant-layout-content {
  background-color: transparent !important;
}

.offer_page .ant-table-tbody>tr>td {
  border-bottom: 10px solid #F0FFCE !important;
}

.offer_page .ant-table-row {
  background: #ffffff !important;
}
.offer_page .page_header {
background: #EEF5CE;
border-radius: 22px;
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
 padding-top: 30px;
  padding-bottom: 30px
}
.offer_page .page_header .title {
font-size:  30px;
font-weight:600;
 color: #1E4321;
}
.offer_page .page_header .search_text {
font-size: 18px;
 font-weight: 500;
}
.offer_page .page_header .search_input {
width: 100%; 
box-shadow: 0px 3px 6px #00000029;
 border-radius: 11px;
  height: 48px
}
.offer_page .record_title {
 color: #325924;
 font-weight: 600!important
}
.offer_page .action_icons img {
 height:24px
}

.offer_modal .add_modal .title{
  font-size:18px;
  color: #424440;
  font-weight: 600 
}
.offer_modal .add_modal .modal_body{
  font-size: 14px;
  color: #424440;
  background: #E5FFD5;
  border-radius: 11px;
  font-weight: 500
}
.offer_modal .add_modal .close_button{
  cursor: pointer;
  background: #D2D1D1;
  border-radius: 8px;
  color: #424440;
   font-size: 15px;
  font-weight: 500;
}
.offer_modal .add_modal .add_button{
  cursor: pointer;
  background: #517744;
  border-radius: 8px;
  color: white;
  font-size:15px;
  font-weight: 500
}

/* all order page  */
.all_order_page .ant-page-header {
  background-color: #A2FFD3 !important;
}

.all_order_page .ant-layout-content {
  background-color: transparent !important;
}

.all_order_page .ant-table-tbody>tr>td {
  border-bottom: 10px solid #A2FFD3 !important;
}

.all_order_page .ant-table-row {
  background: #ffffff !important;
}
.all_order_page .order_id {
  color:#325924;
  font-weight: 600
}

/* subcategory  */
.sub_cat_content{
  min-height: 75vh;
  padding: 10px
}
.custom_table .page_header{
  background: #EEF5CE!important;
  border-radius: 22px!important;
  border-bottom-right-radius:0px; 
  border-bottom-left-radius: 0px; 
  padding-top: 30px;
  padding-bottom: 30px
}
.custom_table .page_header .title{
  font-size:30px;
  font-weight: 600;
  color: #1E4321;
}
.custom_table .page_header .search_text{
  font-size: 18px;
  font-weight: 500
}
.custom_table .page_header .search_box{
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
   height: 48px;
}
.custom_table .text_green{
  color: #325924;
  font-weight: 600
} 
.custom_table .action_icons img{
  height:24px
} 
.custom_table .sub_cat_image{
  height:80px
} 
.sub_cat_modal .modal_body{
 font-size: 14px;
 color: #424440;
 background: #DBFFE3!important;
 border-radius: 11px;
  font-weight: 500
}
.sub_cat_modal .title{
  font-size: 20px;
  color: #424440;
  font-weight: 600
}
.sub_cat_modal .add_button{
  cursor: pointer;
  background: #517744;
  border-radius: 8px;
  color: white; 
  font-size: 15px;
  font-weight: 500;
}
.sub_cat_modal .close_button{
 cursor: pointer;
  background: #D2D1D1;
   border-radius: 8px;
    color: #424440;
     font-size: 15px;
      font-weight: 500;
}
.sub_cat_modal img{
   height:82px; 
   border: 0.5px solid #8c8c8c;
    border-radius: 10px;
}

/* notification */
.btn_add_notification {
  background: #E33A3A;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  color: #FFFFFF;
  font-size: 15px;
  height: 49px !important
}

.btn_add_notification:hover {

  color: #FFFFFF;

}

.text_green {

  color: #325A09
}

.ant-menu-item-selected .ant-menu-title-content {
  color: white !important;
}

.ant-menu-item-selected .ant-menu-item-icon {
  filter: brightness(0) invert(1);
}

/* Dashboard  */
.dashboard-boxes {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6%;
  width: 45%;
  height: 38vh;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 2px 5px black;
}

.dashboard-boxes-image {
  position: absolute;
  overflow: hidden;
  z-index: 0;
  object-fit: fill
}
.dash_view_modal .title{
   font-size: 18px;
   color: #424440;
    font-weight: 500
}
.dash_view_modal .modal_body{
   font-size: 15px;
   color: #050404;
    background: #ecebbf
}
.dash_view_modal .text_green{
   color: #116B07
}
.dash_view_modal .status_label{
   font-size: 14px; color: #424440; font-weight: 500
}
.dash_view_modal .total_amount{
   color: #1D4804; font-size: 32px
}
.dash_table{
   font-size: 12px; 
   border-radius: 18px; 
   background-color: #eee
}
.dash_table .product_charges{
   font-weight: 500;
    font-size: 14px
}
.dash_table .net_total{
   background: #D2D1D1!important; 
   border-radius: 8px; 
   color: #424440
}
.cancel_reason_div{
   margin-top: -50px
}
.cancel_reason_div textarea{
   border: 1px solid grey; 
   border-radius: 10px;
    margin-top: 14px
}
 .dash_view_modal .update_button{
   color: white; 
   background-color: #517744;
    border-radius: 8px
}
 .dash_card_view{
  color: #1C1B1B
}
.dash_card_view .top_heading{
  font-size: 33px;
  font-weight:600;
  letter-spacing: 0px;
  padding-left: 20px
}
.dash_card_view .top_banner{
 border-radius: 26px;
  height: 300px;
  align-items: center;
  display: flex;
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat; 
  background-color: transparent
}
.dash_card_view .top_banner_heading{
  font-size:61px;
  font-weight:600;
  letter-spacing: 0px;
  color: #FFFFFF; opacity: 1
}
.dash_card_view .small_card{
  justify-content: space-between;
  display: flex;
  flex: 1 
}
.dash_card_view .small_card .title{
  font-weight: bold;
   z-index: 10
}
.table_view{
  background-color: #E9F5E7;
  border-radius: 22px
}
.table_view .title{
  font-weight:600!important;
  font-size:30px!important;
  letter-spacing: 0px;
  color: #1E4321;
}
.dash_table .view_button{
  background: #0A7981;
  color: white;
  border-radius: 8px;
  padding-left: 25px;
  padding-right: 25px;
   border: 1px solid #0a7981;
}
.dash_card_view .table_view .action_buttons img{
 height:24px
}
.dash_card_view .table_view .action_buttons i{
 font-size:22px
}

.w-5{
  width:5%;
}


/* user */
.user_filter {
  margin-top: -20px !important;
  min-height: 307px;
  display: flex;
  flex: 1;
  background-image: url('./assets/img/user_top_banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent
}

.user_filter .content_center {
  justify-content: center;
  align-items: center
}

.user_filter label {
  /* font: 600 14px/21px Poppins */
  font-size: 14px;
  font-weight: 600;
  color: #5C4D4D
}

.user_filter button {
  border: 1px solid #16810A;
  background: #16810A;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 12px;
}

.user_filter i {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
}

.user_edit label {
  font-size: 18px;
  color: #424440;
  font-weight: 500
}

.user_edit .contact_detail {
  font-size: 15px;
  color: #050404;
  background: #DEFFB4;
  border-radius: 11px
}

.user_edit span {
  color: #116B07;
}

.primary_detail {
  background: #FFFFFF;
  border-radius: 8px;
  color: #116B07
}

.primary_heading {
  background: #D2D1D1;
  border-radius: 8px;
  color: #424440
}

.primary_detail span {
  font-size: 15px;
  font-weight: 600;
  color: #116B07
}

.secondary_detail {
  font-size: 15px;
  color: #050404;
  background: #F8FABB;
  border-radius: 11px
}

.address_heading {
  background: #D2D1D1;
  border-radius: 8px;
  color: #424440
}

.address_detail {
  background: #FFFFFF;
  border-radius: 8px;
  color: #116B07
}

.address_detail span {
  font-size: 15px;
  font-weight: 500;
  color: #116B07
}

.user_page_header {
  background: #EEF5CE;
  border-radius: 22px;
  border-bottom-left-radius: 0px
}

.page_title {
  font-size: 30px;
  font-weight: 600;
  color: #1E4321 
}

.page_sub_title{
  font-size: 15px;
  color: #434343
}

.serach-modal{
  font-size: 18px;
  font-weight: 500
}

.serach_input{
  width: 100%;
  box-shadow: 0px 3px 6px #00000029; 
  border-radius: 11px; 
  height: 48px 
}

.user_detail_button{
  background: #0A7981;
  color: white;
  border: 1px solid transparent;
  border-radius: 10px
}

/* category */
.add_category_title label{
  font-size: 18px; 
  color: #424440;
  font-weight: 600
}

.add_category_title img{
  height: 82px;
  border: 0.5px solid #8c8c8c;
  border-radius: 10px
}

.category_name{
  font-size: 14px;
  color: #424440; 
  background: #E5FFD5;
  border-radius: 11px; 
  font-weight: 500
}

.category_image{
  cursor: pointer;
  background: #D2D1D1;
  border-radius: 8px;
  color: #424440; 
  font-size: 15px; 
  font-weight: 500
}

.add_category{
  cursor: pointer; 
  background: #517744;
  border-radius: 8px;
  color: white; 
  font-size: 15px; 
  font-weight: 500
}

.edit_category_title label{
  font-size: 18px; 
  color: #424440; 
  font-weight: 600
}

.edit_category_title img{
  height: 82px; 
  border: 0.5px solid #8c8c8c; 
  border-radius: 10px
}

.edit_category_name{
  font-size: 14px; 
  color: #424440; 
  background: #E5FFD5; 
  border-radius: 11px; 
  font-weight: 500
}

.category_close{
  cursor: pointer; 
  background: #D2D1D1; 
  border-radius: 8px; 
  color: #424440; 
  font-size: 15px; 
  font-weight: 500
}

.category_update{
  cursor: pointer; 
  background: #517744; 
  border-radius: 8px; 
  color: white; 
  font-size: 15px; 
  font-weight: 500
}

.category_header{
  background: #EEF5CE; 
  border-radius: 22px; 
  border-bottom-left-radius: 0px; 
  border-bottom-right-radius: 0px; 
  padding-top: 30px; 
  padding-bottom: 30px
}

.category_title{
  font-size: 30px; 
  color: #1E4321
}

.category_search{
  font-size: 18px; 
  font-weight: 500
}

.category_input{
  width: 100%!important; 
  box-shadow: 0px 3px 6px #00000029!important; 
  border-radius: 11px!important; 
  height: 48px!important
}

/* order */
.order_view_header label{
  font-size: 18px; 
  color: #424440; 
  font-weight: 500
}

.order_detail{
  font-size: 15px; 
  color: #050404; 
  background: #ecebbf
}

.order_span span{
  color: #116B07
}

.order_status label{
  font-size: 14px;
  color: #424440;
  font-weight: 500
}

.order_price{
  color: #1D4804; 
  font-size: 32px
}

.order_table{
  font-size: 12px; 
  border-radius: 18px; 
  background-color: #eee
}

.order_table_data{
  font-weight: 500; 
  font-size: 14px
}

.order_span_table{
  background: #D2D1D1; 
  border-radius: 8px; 
  color: #424440 
}

.order_page_header{
  background: #EEF5CE; 
  border-radius: 22px; 
  border-bottom-left-radius: 0px; 
  border-bottom-right-radius: 0px; 
  padding-top: 30px; 
  padding-bottom: 30px 
}

.order_title{
  font-size: 30px;
  color: #1E4321
}

.order_search{
  font-size: 18px; 
  font-weight: 500
}

.order_search_input{
  width: 100%; 
  box-shadow: 0px 3px 6px #00000029; 
  border-radius: 11px; 
  height: 48px
}
.order_sub_title{
  font-weight:400;
  font-size:15px; 
  color:#434343
}

.order_view_button{
  background: #0A7981; 
  color: white; 
  border: 1px solid transparent; 
  border-radius: 10px
}
.content {
  min-height: '75vh';
  padding: '10px'
}

.css-1pahdxg-control{
  box-shadow: none!important;
  border-color: transparent!important;
  background-color: transparent!important;
}
.css-1s2u09g-control{
  border-color: transparent!important;
}

/* user orders  */
.user_order_view_modal .title{
   font-size: 18px;
    color: #424440;
     font-weight: 500
}
.user_order_view_modal .modal_body{
  font-size: 15px; 
  color:#050404;
   background: #ecebbf;
}
.user_order_view_modal .modal_body .text-green{
   color: #116B07;
}
.user_order_view_modal .modal_body .status_label{
   font-size: 14px;
    color: #424440;
     font-weight: 500
}
.user_order_view_modal .modal_body .total_amount{
   color: #1D4804;
    font-size: 32px;
}
.user_order_view_modal .modal_body .table_div{
   font-size: 12px;
    border-radius: 18px;
     border-radius: #eee
}
.user_order_view_modal .modal_body .charge_div{
   font-weight: 500; font-size: 14px;
}
.charge_div .net_total{
  background: #D2D1D1;
  border-radius: 8px;
  color: #424440
}
.user_order_top_banner{
  margin-top:-20px;
  min-height: 307px!important;
  display: flex;
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: transparent
}
.user_order_top_banner .justify_content{
  justify-content: center;
  align-items: center;
}
.user_order_top_banner .input_title{
  font-size:14px;
  color: #5C4D4D;
  font-weight: 500;
}
.user_order_top_banner .align_item_center{
  align-items: center
}
.user_order_top_banner .search_button{
  border:1px solid #16810A;
  background: #16810A;
  border-radius: 8px;
  color: #FFFFFF;
  font-size:12px;
}
.user_order_top_banner .refresh_button{
  font-size:26px;
  cursor:pointer;
}
.user_order_content{
  min-height:75vh;
  padding:10px
}
.user_order_content .page_header{
  background:#E9F5E7;
  border-radius: 22px;
  border-bottom-left-radius:0px;
  border-bottom-right-radius:0px
}
.user_order_page_header .title{
  font-size:30px;
  font-weight:600;
  color:#1E4321;
  text-transform:"capitalize"
}
.user_order_page_header .sub_title{
  font-size:15px;
  color:#434343;
}
.user_order_page_header .search_text{
  font-size: 18px;
  font-weight: 500
}
.user_order_page_header .search_input{
  width:100%;
  box-shadow:0px 3px 6px #00000029;
  border-radius: 11px;
  height: 48px
}

/* product module */
.product_content{
  min-height: 75vh;
  padding: 10px;
}
 .product_modal .title{
  font-size: 18px;
  color: #424440;
  font-weight: 600;
}
 .product_modal .product_image{
  height: 82px; 
  border: 0.5px solid #8c8c8c;
  border-radius: 10px
}
 .product_modal .delete_icon{
  height: 25px; 
 
}
 .product_modal .modal_body{
  font-size: 14px;
   color: #424440;
   background: #E5FFD5; 
  border-radius: 11px; 
  font-weight: 500 
}
 .product_modal .close_button{
  cursor: pointer;
   background: #D2D1D1; 
  border-radius: 8px;
   color: #424440;
    font-size: 15px; 
    font-weight: 500 
}
 .product_modal .add_button{
  cursor: pointer; background: #517744;
  border-radius: 8px; color: white; 
  font-size: 15px; font-weight: 500 
}

.product_page .ant-page-header {
  background-color: #CEFFE8 !important;
}

.product_page .ant-layout-content {
  background-color: transparent !important;
}

.product_page .ant-table-tbody>tr>td {
  border-bottom: 10px solid #CEFFE8 !important;
}
.product_page .page_header {
  background: #EEF5CE;
  border-radius: 22px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top:30px;
  padding-bottom: 30px;
}
.product_page .page_header .title {
  font-size:30px;
  font-weight:600;
   color: #1E4321; 
}
.product_page .page_header .search_text {
  font-size: 18px;
   font-weight: 500 
}
.product_page .page_header .search_input {
  width: "100%";
   box-shadow: 0px 3px 6px #00000029;
   border-radius: 11px; 
   height: 48px; 
}
.product_page .title {
  color: #325924;
   font-weight: 600 
}
.product_page .product_list_image {
 height:80px
}
.product_page .action_icons img{
 height:24px
}


/* banner page  */
/* category table */
.banner_page .ant-page-header {
  background-color:#BCFFC1 !important;
}
.ant-switch-checked{
    background: #80c36b;
}

.banner_page .file_input_color{
  background: #ebebeb!important;
}
.banner_page .card_background{
  background: #BCFFC1!important
}
.banner_page .card{
  font-size: 13px!important;
  border-radius: 13px!important;
}
.banner_page .delete_icon{
  height: 22px!important;
  float: right!important;
}
/* .banner_page .banner_image{
  height:165px!important; 
} */
.banner_page .banner_image{
  width:100%!important; 
}
.banner_page .submit_button{
  height: 40px!important;
  border: 1px solid #16810A!important;
  background: #16810A!important;
  border-radius: 8px!important;
  color: #FFFFFF!important
}

.banner_header{
  background: #BCFFC1; 
  border-radius: 22px; 
  border-bottom-left-radius: 0px; 
  border-bottom-right-radius: 0px; 
  padding-top: 30px; 
  padding-bottom: 30px
}

.banner_title{
  font-size: 30px; 
  color: #1E4321
}


/* feedback page  */
.feedback_modal .ant-modal-body {
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.feedback_modal .view_modal{
  font-size:14px!important;
  color:  #424440!important;
  background: #DEEBEC!important;
  border-radius: 11px!important;
  font-weight: 500!important;
}
.feedback_modal .view_modal label{
  font-size: 20px!important;
  color: #424440!important;
  font-weight: 600!important;
}
.feedback_modal .view_modal .box_border{
  border: 8px solid #80C36B!important;
  border-radius: 10px!important;
   background: white!important;
}
.feedback_modal .view_modal .close_button{
  background-color: #D2D1D1!important 
}
.content_view{
  padding: 10px!important; 
}
.feedback_page .top_banner{
  padding-top: 40px; 
  padding-bottom: 40px!important;
  background-size: cover;
  background-repeat: no-repeat;
   background-color: transparent;
}
.feedback_page .w-80{
  width:80%!important;
}
.feedback_page .title {
  font-size:30px;
  font-weight:600;
  color:#1E4321;
}
.feedback_page label {
  font-size:14px;
  color: #5C4D4D; 
  font-weight: 500 
}
.feedback_page button {
    border:1px solid #16810A;
    background: #16810A; 
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 12px;
}
.feedback_page #react-select-9-listbox {
  z-index: 9999!important;
}
.feedback_page #react-select-3-listbox {
  z-index: 9999!important;
}
.feedback_page #react-select-2-listbox {
  z-index: 9999!important;
}
.feedback_page .category_input {
 width:80%!important
}
.content_view .page_header{
  background: #E9F5E7;
  border-radius: 22px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.content_view .title{
 font-size: 30px;
  color: #1E4321;
  text-transform: capitalize
}
.content_view .search_text{
  font-size: 18px;
  font-weight: 500
}
.content_view .search_input{
  width: 100%;
  box-shadow: 0px 3px 6px #00000029!important;
  border-radius: 11px!important;
  height: 48px!important
}
.content_view .view_icon{
  font-size: 20px; 
  color: gray; 
}
.content_view .delete_icon{
  height: 20px!important;
}
.ant-popover-inner{
  max-height: 300px;
  overflow: auto;
}
.ant-popover{
  position: fixed;
}
/* .ant-popover-placement-bottom {
  left: 70% !important;
} */
/* notification bell  */
.ant-badge-count{
  top:50%!important;
  color: black;
  background-color: white;
  border:1px solid gray;  
  right:14%!important

}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset!important;
}
button:focus{
  outline:none!important;
}

.bg-pink{
  background-color:#FFDBDB;
}


/* order tracker  */

.tracker_page .bg_green{
  background-color:#BCFFC1;
  
}
.tracker_page .bg_green .title{
 font-size:30px;
 color:#1E4321;
 font-weight:bold

}
.tracker_page .bg_green .black_border{
  border-radius:8px;
  background:#696262
}
.tracker_page .bg_green .bg_gray{
  border-radius:8px;
  background:#F7F7F7
}
.tracker_page .bg_green .refresh_button{
  background:#F25B5B;
  color:white;
  border-radius:11px
}
.tracker_page .active_div{
  border-radius:50%;
  border:8px solid green
  
}
.tracker_page .inactive_div{
  border-radius:50%;
  border:8px solid grey;
  margin-left:55px
}

.track_order input{
border:none;
background-color:#E0E0E0;
height:40px;
width: 61%;
border-radius:8px;
background:#E0E0E0
}
.track_order select{
border:none;
background-color:#E0E0E0;
height:40px;
border-radius:8px;
background:#E0E0E0;
width:61%;
}
.track_order button {
  border:1px solid #16810A;
  background: #16810A; 
  border-radius: 8px;
  color: #FFFFFF;

}

.track_order .label_left{
  float:left;
  padding-top:6px;
}

.vl {
  /* border-left: 1px solid grey;
  height: 80px;
  margin-left:106px; */

  border-right: 1px solid gray;
height: 80px;
margin-right: 54px;
}

 .css-26l3qy-menu{
  z-index:9999!important
 }

 /* Notification Page  */
 .notify_modal .ant-modal-body {
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

 .notify_page{
   min-height: 75vh;
   padding: 10px!important
 }
 .notify_modal .ant-modal-body {
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}
 .notify_modal .add_modal{
  background-color: #DEEBEC!important;
   border-radius: 22px!important ;
 }
 .notify_modal .title{
  font-size: 18px!important;
  color: #424440!important; 
  font-weight: 600!important
 }
 .notify_modal .form_div{
  font-size: 14px;
   color: "#424440";
   border-radius: 11px;
   font-weight: 500
 }
 .notify_modal .attach_icon{
    height: 20px!important
 }
 .notify_modal .backgorund_view{
  font-size: 14px;
  color: #424440;
  background: #DEEBEC;
  border-radius: 11px;
  font-weight: 500
 }
 .notify_modal .border_green{
  border: 8px solid #80C36B;
  border-radius: 10px;
  background: white;
 }
 .notify_modal .label{
font-size: 20px;
color: #424440;
 font-weight: 600
 }
 .notify_modal .close_button{
 background-color: #D2D1D1
 }
 .notify_table_view .page_header{
    background: '#EF5CE';
    border-radius: 22px;
    border-bottom-left-radius:0px;
    border-top-right-radius:0px;
    padding-top: 30px;
    padding-bottom: 30px 
 }
.notify_table_view .page_header .title{
  font: 30px;
  color: #1E4321
}
.notify_table_view .page_header .search_text{
  font-size: 18px;
  font-weight: 500
}
.notify_table_view .page_header .search_input{
  width: 100%!important;
  box-shadow: 0px 3px 6px #00000029!important;
 border-radius: 11px!important;
  height: 48px!important
}
.notify_table_view .page_header .text_green{
 color: #325924;
 font-weight: "600"
}
.notify_table_view  .eye_icon i{
  font-size: 20px;
  color: gray
}
.notify_table_view  .delete_icon {
  height: 24px
}

/* Offer  */


 /* Delete buttons */
.btn_delete_yes {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 13px;
  background: #E03333 0% 0% no-repeat padding-box;
  font-family: "Poppins";
  color: #FFFFFF !important;
  cursor: pointer;
  height: 43px;
}

.btn .btn_delete_yes:hover {
  color: white !important;
}

.btn_delete_no {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 13px;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  font-family: "Poppins";
  color: #1E4321 !important;
  cursor: pointer;
  height: 43px;
}

.btn .btn_delete_yes:hover {
  color: white !important;
}

.btn .btn_delete_no:hover {
  color: #1E4321 !important;
}